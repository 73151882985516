import React from 'react'
import { graphql } from 'gatsby'

import SEO from '../components/SEO/SEO'
import DynamicZone from '../components/DynamicZone/DynamicZone'

export default function Home({ data: { strapi: { meetOurAmbassador } }, location }) {

	return (
		<>
			<SEO title="Meet Our Ambassadors" description={ meetOurAmbassador.seoDescription } pathname={ location.pathname } />

			{ meetOurAmbassador && meetOurAmbassador.content &&
				<DynamicZone components={ meetOurAmbassador.content } />
			}
		</>
	)
}

export const query = graphql`
	query meetOurAmbassadorPageQuery {
		strapi {
			meetOurAmbassador {
				seoDescription
				content {
					__typename
					... on StrapiQuery_ComponentCommonStatement {
						statementTitle
						statementSubTitle
					}
					... on StrapiQuery_ComponentProfileProfileList {
						profileListTitle
						profileList {
							id
							profileName
							profileSummary
							profileImage {
								url
								imageFile {
									childImageSharp {
										fluid(maxWidth: 1000) {
											...GatsbyImageSharpFluid_noBase64
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
`